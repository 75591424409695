import { useTranslation } from "src/translations/translationProvider";
import globalStyles from "src/styles/global.module.scss";
import ControlHeader from "src/components/controlHeader/controlHeader";
import styles from "./UploadBatteryAvailabilityPlan.module.scss";
import { useRef, useState } from "react";
import LoadingSpinner from "src/components/loadingSpinner";
import classNames from "classnames";
import DownloadTemplate from "src/modules/energy/availability/downloadTemplate";
import { useMutation, useQuery } from "@apollo/client";
import {
  BatteryAvailabilityPlanInput,
  BatteryAvailabilityPlanValidationError,
  GetBatteriesForOrganizationDocument,
  SaveBatteryAvailabilityDocument,
} from "src/graphql/graphql";
import { templateType } from "src/constants/templateTypes";
import { useNotifications } from "src/modules/notifications/notificationsProvider";
import Button, { BUTTON_STATE } from "src/components/button";
import { ParsedExcelError, parseExcel } from "src/utilities/parseExcel";
import {
  BatteryAvailabilityData,
  ValidateBatteryAvailabilityExcel,
} from "../validateBatteryAvailability";
import { ExcelValidationErrors } from "src/components/excelValidationError/excelValidationError";
import { BatteryAvailabilityUploadChart } from "../BatteryAvailabilityChart/BatteryAvailabilityUploadChart";
import { BatteryAvailabilityPlanValidationErrors } from "./BatteryAvailabilityPlanValidationErrors";
import { ValidationError } from "./ValidationError";
import { createValidationErrors } from "./createValidationErrors";

export const UploadBatteryAvailabilityPlan = ({
  organizationId,
  plantId,
  isActiveTab,
}: {
  organizationId: string;
  plantId: string;
  isActiveTab: boolean;
}) => {
  const { translate } = useTranslation();
  const { showNotification } = useNotifications();

  const [buttonState, setButtonState] = useState<
    (typeof BUTTON_STATE)[keyof typeof BUTTON_STATE]
  >(BUTTON_STATE.DEFAULT);

  const [plans, setPlans] = useState<BatteryAvailabilityPlanInput[]>();
  const [errors, setErrors] = useState<ParsedExcelError[]>();
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>();
  const [canSave, setCanSave] = useState(false);

  const [saveBatteryAvailabilityMutation] = useMutation(
    SaveBatteryAvailabilityDocument,
  );

  const fileInputRef = useRef<HTMLInputElement>(null);

  if (!isActiveTab && fileInputRef.current?.value) {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  }

  const { loading, error, data } = useQuery(
    GetBatteriesForOrganizationDocument,
    {
      variables: {
        organizationId,
      },
    },
  );

  if (error || !data) return <div>{translate("UnexpectedError")}</div>;
  const organization = data?.organization ?? { plants: [] };

  if (loading) return <LoadingSpinner />;

  if (organization.plants.length === 0) {
    return (
      <h3 className={classNames(styles.heading, styles.section)}>
        {translate("No plants found")}
      </h3>
    );
  }

  const handleSave = () => {
    setButtonState(BUTTON_STATE.LOADING);

    if (!plans) {
      return;
    }

    saveBatteryAvailabilityMutation({
      variables: {
        organizationId,
        plantId,
        plans: plans,
      },
    })
      .then(({ data, errors }) => {
        if (errors && errors.length) {
          throw errors;
        }

        if (!data?.saveBatteryAvailability?.success) {
          const backendValidationErrors = data?.saveBatteryAvailability!
            .errors as BatteryAvailabilityPlanValidationError[];
          setValidationErrors(createValidationErrors(backendValidationErrors));

          throw Error("Validation of uploaded availability plan failed");
        }

        setButtonState(BUTTON_STATE.SUCCESS);
        setTimeout(() => {
          setButtonState(BUTTON_STATE.DEFAULT);
        }, 2000);
        showNotification(translate("Successfully saved Availability plan"));
      })
      .catch((error) => {
        console.error("Saving availability failed: ", error);
        setButtonState(BUTTON_STATE.FAIL);
        setTimeout(() => {
          setButtonState(BUTTON_STATE.DEFAULT);
        }, 2000);
        showNotification(translate("Saving availability plan failed"));
      });
  };

  const handleFileChange = (file: File) => {
    setValidationErrors([]);

    parseExcel<BatteryAvailabilityData>(file, (result) => {
      const { errors, values } = ValidateBatteryAvailabilityExcel(result);
      if (errors.length > 0) {
        setErrors(errors);
        setCanSave(false);
      } else {
        setPlans(values);
        setCanSave(true);
      }
    });
  };

  return (
    <section>
      <header className={globalStyles.subTitle}>
        {translate("DataPlanDownloadInstructions")}
      </header>
      <ControlHeader className={styles.controlHeader}>
        <div className={styles.fileUpload}>
          <input
            type="file"
            name="batteryAvailability"
            onChange={(e) => {
              const file = e.target.files?.[0];
              if (!file) {
                return;
              }
              handleFileChange(file);
            }}
          ></input>
        </div>
        <DownloadTemplate
          className={styles.downloadTemplate}
          organizationId={organizationId}
          plantId={plantId}
          plantType={templateType.BATTERY}
        />
      </ControlHeader>
      <main className={styles.verification}>
        <div className={globalStyles.sectionTitleLarge}></div>
        <BatteryAvailabilityUploadChart
          availabilityPlans={plans}
          className={styles.chart}
        />
        <ExcelValidationErrors errors={errors || []} />
        <BatteryAvailabilityPlanValidationErrors
          validationErrors={validationErrors || []}
        />
      </main>
      <Button
        state={buttonState}
        disabled={!canSave}
        onClick={handleSave}
        small
      >
        {translate("Save")}
      </Button>
    </section>
  );
};
