import { IncomingDataPlanResponse, PeriodSize } from "src/graphql/graphql";
import { addDays, addHours, addMinutes, differenceInHours, differenceInMinutes, formatISO, getISODay, parseISO } from "date-fns";
import { DataPlanGraph } from "../dataPlanModel";
import { LanguageKey } from "src/config/translations/translationsTypes";

export function mapToDataPlanGraph(responses: (IncomingDataPlanResponse | null)[]): DataPlanGraph[] {
  if (!responses || responses.length === 0) {
    return [];
  }

  return responses.flatMap((response) => {
    if (!response) return [];

    const { increment, incrementValue } = getPeriodSizeConfig(response.periodSize);

    const allTimestamps: DataPlanGraph[] = [];

    const filteredValues = response.values?.filter(
      entry => entry && entry.timestamp && entry.value !== null && entry.value !== undefined
    );

    // process and generate missing timeseries with null value
    if (filteredValues && filteredValues.length > 0) {
      let currentTimestamp = new Date(filteredValues[0]!.timestamp);
      const lastTimestamp = new Date(filteredValues[filteredValues.length - 1]!.timestamp);

      while (currentTimestamp <= lastTimestamp) {
        const nextTimestamp = increment(currentTimestamp, incrementValue);
        const timestampString = formatISO(currentTimestamp);

        const existingEntry = filteredValues.find(entry => formatISO(parseISO(entry!.timestamp)) === timestampString);

        allTimestamps.push({
          fromTimestamp: formatISO(currentTimestamp),
          toTimestamp: formatISO(nextTimestamp),
          value: [{ name: response.name, value: existingEntry ? existingEntry.value : null }],
        });

        currentTimestamp = nextTimestamp;
      }
    }

    return allTimestamps;
  });
}

export function getPeriodSizeConfig(periodSize: PeriodSize) {
  switch (periodSize) {
    case PeriodSize.M15:
      return {
        increment: addMinutes,
        incrementValue: 15,
        difference: differenceInMinutes,
      };
    case PeriodSize.H1:
      return {
        increment: addHours,
        incrementValue: 1,
        difference: differenceInHours,
      };
    default:
      throw new Error(`Unsupported PeriodSize: ${periodSize}`);
  }
}

export function getWeekDateRangeISO(today: string): { From: string; To: string } {
  const parsedDate = parseISO(today);
  const isoWeekday = getISODay(parsedDate);

  const startOfWeek = addDays(parsedDate, - (isoWeekday - 1));
  const endOfWeek = addDays(startOfWeek, 6).setHours(23, 59, 59, 59);
  
  return {
    From: formatISO(startOfWeek),
    To: formatISO(endOfWeek), 
  };
}

export function tryGetTranslatedHeaderName(translate: (value: LanguageKey) => string, name: string)
{
  if(name.includes("Production")){
    return translate("Production");
  }
  if(name.includes("Consumption")){
    return translate("Consumption");
  }

  return name;
}