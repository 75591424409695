import classNames from "classnames";
import styles from "./tableCellFormats.module.scss";

interface RawValueProps {
  value?: string | number | null;
  className?: string;
  unit?: string;
}

const RawValue = ({ value, className, unit }: RawValueProps) => {
  return value === undefined || value === null ? (
    <></>
  ) : (
    <div className={classNames(styles.formatOrEmpty, className)}>
      {`${value} ${unit || ""}`}
    </div>
  );
};

export default RawValue;
