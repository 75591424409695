import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { CaretRightIcon as ExpandIcon } from "src/icons";

import styles from "./listContainer.module.scss";

const ListContainer = ({ title, children, headerClass, isPortfolio }) => {
  const ref = useRef();
  const [open, setOpen] = useState(false);

  return (
    <div
      className={classNames(styles.container, {
        [styles.portfolio]: isPortfolio,
        [styles.open]: open,
      })}
      data-test-id={`list-container-expand${title}`}
    >
      <div
        className={classNames(styles.header, headerClass, {
          [styles.portfolio]: isPortfolio,
        })}
        onClick={() => setOpen(!open)}
      >
        <span className={styles.title}>{title}</span>
        <ExpandIcon
          className={classNames(styles.expand, {
            [styles.open]: open,
          })}
        />
      </div>
      <div
        ref={ref}
        className={classNames(styles.item, {
          [styles.portfolio]: isPortfolio,
          [styles.open]: open,
        })}
        style={{
          height: open ? ref.current.scrollHeight + 1 : "0px", // +1px or the last items bottom border is hidden
        }}
      >
        {children}
      </div>
    </div>
  );
};
ListContainer.propTypes = {
  title: PropTypes.string,
  headerClass: PropTypes.string,
  children: PropTypes.node,
  isPortfolio: PropTypes.bool,
};

export default ListContainer;
