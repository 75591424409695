import React from "react";
import { useTranslation } from "src/translations/translationProvider";
import styles from "./BatteryAvailabilityPlanValidationErrors.module.scss";
import { ValidationError } from "./ValidationError";

export function BatteryAvailabilityPlanValidationErrors({
  validationErrors,
}: {
  validationErrors: ValidationError[];
}) {
  const { translate } = useTranslation();

  const printLineInformation = (validationError: ValidationError) : string => {
    return validationError.line! > 0 ? `${translate("row")}: ${validationError.line! + 1} -` : "";
  };

  return (
    <div className={styles.errorSection}>
      {validationErrors?.length > 0 && (
        <>
          <h3>{translate("BatteryAvailabilityValidationErrors")}</h3>
          {validationErrors.map((validationError) => (
            <div className={styles.error} key={`${validationError.line}-${validationError.message}`}>
              <p>
                {`
                  ${printLineInformation(validationError)}
                  ${translate(validationError.message)}
                `}
              </p>
            </div>
          ))}
        </>
      )}
    </div>
  );
}
