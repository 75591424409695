import { LanguageKey } from "src/config/translations/translationsTypes";
import { DataPlanGraph } from "../dataPlanModel";
import { tryGetTranslatedHeaderName } from "../utils/incomingDataPlanUtils";

export function createChartDataset({
  dataPlans,
  translate,
}: {
  dataPlans: DataPlanGraph[];
  translate: (value: LanguageKey) => string;
}) {
  const uniqueNames = new Set<string>();

  dataPlans.forEach((plan) => {
    plan.value.forEach((entry) => uniqueNames.add(entry.name));
  });

  const maxY = Math.max(
    ...dataPlans.flatMap((plan) =>
      plan.value
        .map((entry) => entry.value)
        .filter((val) => val !== null && val !== undefined)
    )
  );

  const datasets = Array.from(uniqueNames).map((name) => {
    const color = getColorForName(name);
    return {
      label: tryGetTranslatedHeaderName(translate, name) + " (MW)",
      fill: false,
      borderColor: color,
      backgroundColor: color,
      borderWidth: 2,
      pointRadius: 0,
      pointHitRadius: 16,
      yAxisID: "y",
      data: dataPlans.flatMap((plan) => [
        {
          x: new Date(plan.fromTimestamp),
          y: plan.value.find((entry) => entry.name === name)?.value ?? null,
        },
        {
          x: new Date(plan.toTimestamp),
          y: plan.value.find((entry) => entry.name === name)?.value ?? null,
        },
      ]),
      spanGaps: false,
    }
  });

  const hasMissingData = dataPlans.some((plan) => plan.value.length === 0);

  const missingDataSet = hasMissingData
    ? {
        label: translate("Missing timeseries"),
        fill: true,
        borderColor: "rgba(255, 0, 0, 0.5)",
        backgroundColor: "rgba(255, 0, 0, 0.2)",
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 0,
        data: dataPlans.flatMap((plan) => [
          {
            x: new Date(plan.fromTimestamp),
            y: plan.value.length === 0 ? maxY : 0,
          },
          {
            x: new Date(plan.toTimestamp),
            y: plan.value.length === 0 ? maxY : 0,
          },
        ]),
        spanGaps: false,
      }
    : null;

  return {
    datasets: missingDataSet ? [...datasets, missingDataSet] : datasets,
  };
}

function getColorForName(name: string): string {
  if (name.includes("Production")) return "#25a772"; 
  if (name.includes("Consumption")) return "#f08517";
  return getRandomColor();
}

function getRandomColor(): string {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
}
