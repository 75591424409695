import classNames from "classnames";
import { useTranslation } from "src/translations/translationProvider";
import { formatNumber } from "src/utilities";

import styles from "./tableCellFormats.module.scss";

interface FormatOrEmptyProps {
  value?: string | number | null;
  className?: string;
  unit?: string;
}

const FormatOrEmpty = ({ value, className, unit }: FormatOrEmptyProps) => {
  const { locale } = useTranslation();

  return value === undefined || value === null ? (
    <></>
  ) : (
    <div className={classNames(styles.formatOrEmpty, className)}>
      {`${formatNumber(value, locale)} ${unit || ""}`}
    </div>
  );
};

export default FormatOrEmpty;