import { gql } from "@apollo/client";

export const GET_MEASURANDS = gql`
  query getMeasurands($plantId: String!, $organizationId: String!) {
    clientAdministration {
      organization(id: $organizationId) {
        name
      }
      plant(id: $plantId, organizationId: $organizationId) {
        id
        name
        measurands {
          id
          name
          type
          measurandId
          unit
          belongsToThirdParty
          isGenerated
        }
      }
      validMeasurandIds
    }
  }
`;
