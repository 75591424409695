import { BatteryAvailabilityPlanValue } from "src/graphql/graphql";
import { LanguageKey } from "src/config/translations/translationsTypes";

export function createBatteryChartDataset({
  availabilityPlans,
  translate,
}: {
  availabilityPlans: BatteryAvailabilityPlanValue[];
  translate: (value: LanguageKey) => string;
}) {
  return {
    datasets: [
      {
        label: translate("PrequalifiedEffect") + " (MW)",
        fill: false,
        borderColor: "#25a772",
        borderWidth: "2",
        backgroundColor: "#25a772",
        pointRadius: 0,
        pointHitRadius: 16,
        yAxisID: "y",
        spanGaps: true,
        data: availabilityPlans.flatMap((plan) => [
          {
            x: new Date(plan.timestamp),
            y: plan.power,
          },
        ]),
      },
    ],
  };
}
