import { Helmet } from "react-helmet";
import { PageLayout } from "src/components/pageLayout/pageLayout";
import { useGetCurrentUser } from "src/modules/userProvider";
import { useTranslation } from "src/translations/translationProvider";
import { useNavigate, useParams } from "react-router-dom";
import ErrorPage from "src/modules/error-page";
import { Mobile, NotMobile } from "src/components/responsive";
import styles from "./CustomerData.module.scss";
import TabBar from "src/components/tabBar";
import NotIframe from "src/components/notIframe";
import { useState } from "react";
import { ProductionConsumption } from "./ProductionConsumption/ProductionConsumption";
import { BatteryAvailability } from "./BatteryAvailability/BatteryAvailability";
import { hasAccess } from "src/components/accessControl/accessCheck";
import { tabRestrictionLookup, TABS, TabTranslationKey } from "./tabs";

export function CustomerData() {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const currentUser = useGetCurrentUser();
  const userClaims = currentUser.selectedOrganizationConnection.role.claims;
  const params = useParams();

  const organizationId =
    currentUser.selectedOrganizationConnection.organization.id;

  const tabs = Object.keys(TABS)
    .filter((tab) => {
      const restrictions = tabRestrictionLookup[tab];
      if (Array.isArray(restrictions)) {
        return restrictions.every((r) => hasAccess(r, userClaims));
      }
      return hasAccess(restrictions, userClaims);
    })
    .map((tab) => ({
      text: translate(`TABS_${tab}` as TabTranslationKey),
      value: tab,
    }));

  const [activeTab, setActiveTab] = useState(
    (params?.tab || tabs[0]!.value).toUpperCase(),
  );

  const onTabChange = (tab: string) => {
    setActiveTab(tab);
    if (document.startViewTransition) {
      document.startViewTransition(() =>
        navigate(`/customerdata/${tab.toLowerCase()}`),
      );
    } else {
      navigate(`/customerdata/${tab.toLowerCase()}`);
    }
  };

  const renderTabChange = () => {
    if (!currentUser?.selectedOrganizationConnection?.organization?.id) {
      return <ErrorPage />;
    }

    switch (activeTab) {
      case TABS.PRODUCTION_CONSUMPTION:
        return <ProductionConsumption organizationId={organizationId} />;
      case TABS.BATTERY_AVAILABILITY:
        return <BatteryAvailability organizationId={organizationId} />
      default:
        return <ErrorPage />;
    }
  };

  return (
    <section>
      <Helmet>
        <title>{`Modity - ${translate("Menu_CustomerData")}`}</title>
      </Helmet>
      <PageLayout title={translate("Heading_CustomerData")}>
        <NotIframe>
          <NotMobile>
            <TabBar
              className={styles.tabs}
              optionClassName={styles.tab}
              activeOptionClassName={styles.activeTab}
              options={tabs}
              onChange={onTabChange}
              selectedValue={activeTab}
            />
          </NotMobile>
          <Mobile>
            <TabBar
              options={tabs}
              onChange={onTabChange}
              selectedValue={activeTab}
            />
          </Mobile>
        </NotIframe>
        <main className={styles.content}>{renderTabChange()}</main>
      </PageLayout>
    </section>
  );
}
