import Table from "src/components/table";
import styles from "./IncomingDataPlanTable.module.scss";
import { useTranslation } from "src/translations/translationProvider";
import { LanguageKey } from "src/config/translations/translationsTypes";
import { DataPlanGraph } from "../dataPlanModel";
import { formatNumber } from "src/utilities";
import { tryGetTranslatedHeaderName } from "../utils/incomingDataPlanUtils";

interface TransformedDataPlan {
  fromTimestamp: string;
  toTimestamp: string;
  [key: string]: string | number | null;
}

export const IncomingDataPlanTable = ({ dataPlans } : { dataPlans: DataPlanGraph[] }) => {
  const { translate, formatDate, locale } = useTranslation();
  
  const flattenData = transformDataPlanGraph(dataPlans);

  return (
    <Table
      headerClassName={styles.cell}
      cellClassName={styles.cell}
      data={flattenData}
      columns={getColumnsData(dataPlans, translate, formatDate, locale)}
      sortable
      exportable
      tableOptions={{
        initialState: {
          sortBy: [{
            id: "fromTimestamp",
            desc: false,
          }],
        },
        disableSortRemove: true,
      }}
      highlightChangedRow={{ propToCheck: "fromTimestamp" }}
      clickable={{}} 
      className={""} 
      useFooter={false} 
      selectable={false} 
      selectedButtonText="Select Rows" 
      handleSelected={() => {}} 
      truncateDate={false}
    />
  );
};

function getColumnsData(
  dataPlans: DataPlanGraph[], 
  translate: (value: LanguageKey) => string, 
  formatDate: (date: number | Date, formatStr?: string) => string,
  locale: string) {
  
  const uniqueNames = Array.from(
    new Set(dataPlans.flatMap(plan => plan.value.map(entry => entry.name)))
  );

  const columns = [
  {
    Header: () => 
    <div>
      <div className={styles.columnHeaderLabel}>{translate("From")}</div>
      <div className={styles.columnHeaderUnit}>UTC</div>
    </div>,
    accessor: "fromTimestamp",
    Cell: ({ value }: { value:string }) => formatDate(new Date(value), "yyyy-MM-dd HH:mm"),
    name: translate("From"),
    show: true,
  },
  {
    Header: () => 
    <div>
      <div className={styles.columnHeaderLabel}>{translate("To")}</div>
      <div className={styles.columnHeaderUnit}>UTC</div>
    </div>,
    accessor: "toTimestamp",
    Cell: ({ value }: { value:string }) => formatDate(new Date(value), "yyyy-MM-dd HH:mm"),
    name: translate("To"),
    show: true,
  }];

  uniqueNames.forEach((name) => {
    columns.push({
    Header: () => 
    <div>
      <div className={styles.columnHeaderLabel}>{tryGetTranslatedHeaderName(translate, name)}</div>
      <div className={styles.columnHeaderUnit}>MW</div>
    </div>,
    accessor: name,
    Cell: ({ value }: { value: string }) => formatNumber(value, locale)?.toString() ?? "", 
    name: name,
    show: true,
    });
  });

  return columns;
}

function transformDataPlanGraph(dataPlans: DataPlanGraph[]): TransformedDataPlan[] {
  const groupedData: Record<string, TransformedDataPlan> = {};

  dataPlans.forEach(plan => {
    const key = `${plan.fromTimestamp}_${plan.toTimestamp}`;

    if (!groupedData[key]) {
      groupedData[key] = {
        fromTimestamp: plan.fromTimestamp,
        toTimestamp: plan.toTimestamp,
      };
    }

    plan.value.forEach(entry => {
      groupedData[key]![entry.name] = entry.value;
    });
  });

  return Object.values(groupedData);
}