type TabPermission = {
  type: string;
  permission: string;
};

type TabKey = keyof typeof TABS;
export type TabTranslationKey = `TABS_${TabKey}`;


export const TABS = {
  PRODUCTION_CONSUMPTION: "PRODUCTION_CONSUMPTION",
  BATTERY_AVAILABILITY: "BATTERY_AVAILABILITY",
};
  
export const tabRestrictionLookup: Record<string, TabPermission[]> = {
  PRODUCTION_CONSUMPTION: [
    {
      type: "IncomingData",
      permission: "Read",
    }
  ],
  BATTERY_AVAILABILITY: [
    {
      type: "Flexibility",
      permission: "Read",
    }
  ]
};
  