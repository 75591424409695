import { useRef, useState } from "react";
import { useTranslation } from "src/translations/translationProvider";
import Button, { BUTTON_STATE } from "src/components/button";
import ControlHeader from "src/components/controlHeader/controlHeader";
import globalStyles from "src/styles/global.module.scss";
import styles from "./UploadProductionConsumptionPlan.module.scss";
import { ExcelValidationErrors } from "src/components/excelValidationError/excelValidationError";
import { parseExcel } from "src/utilities/parseExcel";
import { useMutation } from "@apollo/client";
import { PeriodSize, ProductionConsumptionPlanInput, UploadProductionConsumptionPlansDocument } from "src/graphql/graphql";
import { useNotifications } from "src/modules/notifications/notificationsProvider";
import { MissingTimeRange, MissingTimeRangeResult, ParsedExcelError, ProductionConsumptionExcelData } from "./uploadProductionConsumptionPlanModel";
import { DownloadProductionConsumptionPlanTemplate } from "./DownloadProductionConsumptionPlanTemplate/DownloadProductionConsumptionPlanTemplate";
import { DataPlanGraph } from "../dataPlanModel";
import { validateAndGetMissingTimeRange, validateParsedIncomingDataPlanExcel } from "./validations/validateProductionConsumptionPlan";
import { segmentTimeRange } from "../utils/uploadProductionConsumptionPlanUtils";
import { IncomingDataPlanChart } from "../DataPlanChart/DataPlanChart";


export const UploadProductionConsumptionPlan = ({ 
  organizationId, 
  periodSize,
  selectedGroupId,
  isActiveTab, 
} : { 
  organizationId: string;
  periodSize: PeriodSize;
  selectedGroupId: number;
  isActiveTab: boolean; 
}) => {
  const [plans, setPlans] = useState<ProductionConsumptionPlanInput[]>([]);
  const [plansGraphs, setPlansGraphs] = useState<DataPlanGraph[]>([])
  const [missingTimeRangeResult, setMissingTimeRangeResult] = useState<MissingTimeRangeResult>({matchedPeriodSize: true, missingHours: []});
  const [errors, setErrors] = useState<ParsedExcelError[]>();
  const [canSave, setCanSave] = useState(false);
  const [buttonState, setButtonState] = useState<(typeof BUTTON_STATE)[keyof typeof BUTTON_STATE]>(BUTTON_STATE.DEFAULT);
  const { translate } = useTranslation();
  const { showNotification } = useNotifications();
  const fileInputRef = useRef<HTMLInputElement>(null);
  
  const [uploadProductionConsumptionPlanMutation] = useMutation(UploadProductionConsumptionPlansDocument, {
    onCompleted: () => {
      setButtonState(BUTTON_STATE.SUCCESS);
        setTimeout(() => {
          setButtonState(BUTTON_STATE.DEFAULT);
        }, 2000);
      showNotification(translate("Successfully saved data plans"));
      setPlansGraphs([]);
      setPlans([]); 
      setErrors([]);
      setCanSave(false);

      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    },
    onError: () => {
      setButtonState(BUTTON_STATE.FAIL);
        setTimeout(() => {
          setButtonState(BUTTON_STATE.DEFAULT);
        }, 2000);
      showNotification(
        translate("Saving data plans failed"),
      );
    },
  });

  const handleSave = () => {
    setButtonState(BUTTON_STATE.LOADING);

    if (!plans) {
      return;
    }

    uploadProductionConsumptionPlanMutation({
      variables: {
        organizationId,
        dataGroupId: selectedGroupId,
        plans: plans,
      },
    })
  };

  if (!isActiveTab && fileInputRef.current?.value) {
    setPlansGraphs([]);
    setPlans([]); 
    setErrors([]);
    setCanSave(false);

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  }

  const handleFileChange = (file: File) => {
    parseExcel<ProductionConsumptionExcelData>(file, (result) => {
      setCanSave(false);
      setErrors([]);
      setMissingTimeRangeResult({matchedPeriodSize: true, missingHours: []});
      
      const { errors, values } = validateParsedIncomingDataPlanExcel(result);
      const segmentedValues = segmentTimeRange(values, periodSize)
      const missingHoursResult = validateAndGetMissingTimeRange(segmentedValues, periodSize);
      const planForGraph = getPlanForGraph(segmentedValues, missingHoursResult.missingHours);

      if (errors.length > 0) {
        setErrors(errors);
      } else {
        setPlansGraphs(planForGraph);
        if (missingHoursResult.missingHours.length > 0 || !missingHoursResult.matchedPeriodSize) {
          setMissingTimeRangeResult(missingHoursResult);
        } else {
            setPlans(segmentedValues);
            setCanSave(true);
        }
      }
    });
  };

  const getPlanForGraph = (
    inputs: ProductionConsumptionPlanInput[],
    missingHourRanges: MissingTimeRange[]
  ): DataPlanGraph[] => {
    const missingData: DataPlanGraph[] = missingHourRanges.map((range) => ({
      fromTimestamp: range.fromTimestamp,
      toTimestamp: range.toTimestamp, 
      value: [],
    }));
  
    const inputData: DataPlanGraph[] = inputs.map((input) => ({
      fromTimestamp: input.fromTimestamp,
      toTimestamp: input.toTimestamp,
      value: [
        { name: "Production", value: input.production ?? null },
        { name: "Consumption", value: input.consumption ?? null },
      ].filter((entry) => entry.value !== null),
    }));
  
    return [...inputData, ...missingData].sort(
      (a, b) => new Date(a.fromTimestamp).getTime() - new Date(b.fromTimestamp).getTime()
    );
  };

  return (
    <div>
      <header className={globalStyles.subTitle}>
        {translate("DataPlanDownloadInstructions")}
      </header>
      <ControlHeader className={styles.controlHeader}>
        <div className={styles.fileUpload}>
          <input
            type="file"
            name="incomingDataPlan"
            ref={fileInputRef}
            onChange={(e) => {
              const file = e.target.files?.[0];
              if (!file) {
                return;
              }
              handleFileChange(file);
            }}
          ></input>
        </div>

      </ControlHeader>
      <DownloadProductionConsumptionPlanTemplate
          organizationId={organizationId}
          groupId={selectedGroupId}
        />
      <main className={styles.verification}>
        <ExcelValidationErrors errors={errors || []} missingTimeRangeResult={missingTimeRangeResult || []} />
        <div className={globalStyles.sectionTitleLarge}>
          <IncomingDataPlanChart
            dataPlanGraphs={plansGraphs}
            className={styles.chart}
          />
        </div>
      </main>
      <Button
        state={buttonState}
        disabled={!canSave}
        onClick={handleSave}
        small>
          {translate("Save")}
      </Button>
    </div>
  );
}
