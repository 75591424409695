/*
  This code comes from this github package: https://github.com/gargroh/react-table-plugins
  We used to have it as a dependency but because it held us back from upgrading React we just borrowed the code for the one plugin we actually used
*/
import React, { useCallback } from "react";
import { ensurePluginOrder } from "react-table";

export function getFirstDefined(...args) {
  for (let i = 0; i < args.length; i += 1) {
    if (typeof args[i] !== "undefined") {
      return args[i];
    }
  }
}

// Get exported file name(do not specify extension here)
const defaultGetExportFileName = ({ all }) => {
  return `${all ? "all-" : ""}data`;
};

// To get column name while exporting
const defaultGetColumnExportValue = (col) => {
  let name = col.Header;
  if (typeof name === "object" || typeof name === "function") {
    name = col.id;
  }
  return name;
};

// To get cell value while exporting
// Checking if it is a react component and returning values that can be exported
const defaultGetCellExportValue = (row, col) => {
  const rawCellValue = row.values[col.id];

  if (React.isValidElement(rawCellValue)) {
    console.error(`Error: rawCellValue in column "${col.id}" is a React element. Returning empty string.`);
    return "";
  }

  if (typeof col.Cell !== "function") {
    return rawCellValue;
  }

  const formattedCellValue = col.Cell({ value: rawCellValue, row });

  if (React.isValidElement(formattedCellValue)) {
    console.error(`Error: formattedCellValue in column "${col.id}" is React element. Returning original value.`);
    return rawCellValue;
  }

  return formattedCellValue;
};

const defaultGetExportFileBlob = () => {
  throw new Error("React Table: Export Blob is mandatory");
};

export const useExportData = (hooks) => {
  hooks.useInstance.push(useInstance);
};

useExportData.pluginName = "useExportData";

function useInstance(instance) {
  const {
    rows,
    initialRows = [],
    allColumns,
    disableExport,
    getExportFileName = defaultGetExportFileName,
    getExportFileBlob = defaultGetExportFileBlob,
    plugins,
  } = instance;

  ensurePluginOrder(
    plugins,
    ["useColumnOrder", "useColumnVisibility", "useFilters", "useSortBy"],
    "useExportData"
  );

  // Adding `canExport` & `exportValue` meta data
  allColumns.forEach((column) => {
    const { accessor, getColumnExportValue = defaultGetColumnExportValue } =
      column;

    const canExport = accessor
      ? getFirstDefined(
          column.disableExport === true ? false : undefined,
          disableExport === true ? false : undefined,
          true
        )
      : false;

    column.canExport = canExport;
    column.exportValue = getColumnExportValue(column);
  });

  // This method will enable export of data on `instance` object
  const exportData = useCallback(
    (fileType, all = false) => {
      // Columns which are exportable
      const exportableColumns = allColumns.filter(
        (col) => col.canExport && (all || col.isVisible)
      );

      if (exportableColumns.length === 0) {
        console.warn("No exportable columns are available");
      }

      // Rows which are exportable
      let exportableRows = (all ? initialRows : rows).map((row) => {
        return exportableColumns.map((col) => {
          const { getCellExportValue = defaultGetCellExportValue } = col;

          return getCellExportValue(row, col);
        });
      });

      // Getting fileName
      const fileName = getExportFileName({ fileType, all });

      // Get `FileBlob` to download
      let fileBlob = getExportFileBlob({
        columns: exportableColumns,
        data: exportableRows,
        fileName,
        fileType,
      });

      // Trigger download in browser
      if (fileBlob) {
        downloadFileViaBlob(fileBlob, fileName, fileType);
      }
    },
    [getExportFileBlob, getExportFileName, initialRows, rows, allColumns]
  );

  Object.assign(instance, {
    exportData,
  });
}

function downloadFileViaBlob(fileBlob, fileName, type) {
  if (fileBlob) {
    const dataUrl = URL.createObjectURL(fileBlob);
    const link = document.createElement("a");
    link.download = `${fileName}.${type}`;
    link.href = dataUrl;
    link.click();
  }
}
