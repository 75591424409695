import { BatteryAvailabilityPlanValue } from "src/graphql/graphql";
import { useTranslation } from "src/translations/translationProvider";
import Chart, {
  PlaceholderChart,
  PlaceholderChartText,
} from "src/components/chart";
import { createChartOptions } from "src/modules/energy/availability/createChartOptions";
import { createBatteryChartDataset } from "./createBatteryChartDataset";

export function BatteryAvailabilityChart({
  availabilityPlans,
  className,
}: {
  availabilityPlans: BatteryAvailabilityPlanValue[] | undefined;
  className?: string;
}) {
  const { translate, locale } = useTranslation();

  if (!availabilityPlans) {
    return (
      <PlaceholderChart
        className={className}
        key="availability plan chart error"
      >
        <PlaceholderChartText>
          {translate("No data found")}
        </PlaceholderChartText>
      </PlaceholderChart>
    );
  }

  return (
    <Chart
      className={className}
      data={createBatteryChartDataset({ availabilityPlans, translate })}
      options={createChartOptions({ translate, locale })}
    />
  );
}
