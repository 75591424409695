import { useState } from "react";
import { useTranslation } from "src/translations/translationProvider";
import config from "src/config/config";
import styles from "./DownloadProductionConsumptionPlanTemplate.module.scss";
import { Button, Modal, NumberInput } from "@mantine/core";
import { getToken } from "src/auth/authenticationStrategies";

const MINIMUM_DAYS= 1;
const DEFAULT_DAYS = 7;
const MAXIMUM_DAYS= 365;

export const DownloadProductionConsumptionPlanTemplate = ({ organizationId, groupId } : 
  {organizationId: string, groupId: number}) => {
  const { translate, language } = useTranslation();
  const [amountOfDays, setAmountOfDays] = useState(DEFAULT_DAYS);
  const [openDialog, setOpenDialog] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const utf8FileNameRegex = /filename\*=UTF-8''(.+?)($|;)/;
  const fallbackFileNameRegex = /filename=["']?(.+?)["']?($|;)/;

  const downloadUrl = `${config.fileProxyURL}/incomingdata/${organizationId}/template/${groupId}?days=${amountOfDays}&language=${language}`;

  const dayInputValid = (days: number) => days <= MAXIMUM_DAYS && days >= MINIMUM_DAYS;

  const handleDownload = async () => {
    try {
      setDownloading(true);
      const token = await getToken();
      const response = await fetch(downloadUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to download the file");
      }

      const contentDisposition = response.headers.get("content-disposition");
      const fileNameMatch =
        contentDisposition!.match(utf8FileNameRegex) ||
        contentDisposition!.match(fallbackFileNameRegex);

      const blob = await response.blob();
      const objectUrl = URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      if (fileNameMatch) {
        anchor.download = decodeURI(fileNameMatch[1] || "template.xlsx");
      }
      anchor.href = objectUrl;
      anchor.click();

      URL.revokeObjectURL(objectUrl);
    } catch (error) {
      console.error("Download failed", error);
    } finally {
      setDownloading(false);
      setOpenDialog(false);
    }
  };

  return (
    <div>
      <Button
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          setOpenDialog(true);
        }}
      >
        {translate("Download Template")}
      </Button>
      {openDialog && (
        <Modal
        opened={openDialog}
        onClose={() => setOpenDialog(false)}
        centered
        title={translate("downloadTemplateDialogTitle")}
      >
        <div>
        <NumberInput
          label={translate("Amount of days")}
          className={styles.numberInput}
          value={amountOfDays}
          onChange={(value) => setAmountOfDays(Number(value) || 0)}
          min={MINIMUM_DAYS}
          max={MAXIMUM_DAYS}
          step={1}
          error={!dayInputValid(amountOfDays) && translate("planDayError")}
          />

          <div className={styles.dialogActions}>
            <Button
              className={styles.button}
              size="sm"
              variant="outline"
              onClick={() => setOpenDialog(false)}
            >
              {translate("Cancel")}
            </Button>
            <Button
                className={styles.button}
                size="sm"
                disabled={!dayInputValid(amountOfDays) || downloading}
                onClick={handleDownload}
                loading={downloading}
              >
              {translate("Download Template")}
            </Button>
          </div>
        </div>
      </Modal>
      )}
    </div>
)};

