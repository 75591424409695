import { NumberInput } from "@mantine/core";
import styles from "./numInput.module.scss";

interface NumInputProps {
    label: string;
    value: number | string;
    onChange: () => void;
    min?: number;
    max?: number;
}
const NumInput = ({label, value, onChange, min, max}: NumInputProps) => {
    return (
        <NumberInput 
            classNames={{
                label: styles.label,
                input: styles.input
            }}
            label={label}
            value={value}
            onChange={onChange}
            min={min}
            max={max}
        />
    )
}

export default NumInput;