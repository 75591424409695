import { PeriodSize, ProductionConsumptionPlanInput } from "src/graphql/graphql";
import { formatISO, parseISO } from "date-fns";
import { getPeriodSizeConfig } from "./incomingDataPlanUtils";


export function segmentTimeRange(
  incomingDataPlans: ProductionConsumptionPlanInput[],
  periodSize: PeriodSize
): ProductionConsumptionPlanInput[] {
  if (incomingDataPlans.length !== 1) return incomingDataPlans;

  const { fromTimestamp, toTimestamp, production, consumption } = incomingDataPlans[0]!;
  const segments: ProductionConsumptionPlanInput[] = [];

  const { increment, incrementValue } = getPeriodSizeConfig(periodSize);

  let currentFromTimestamp = parseISO(fromTimestamp);
  const endTimestamp = parseISO(toTimestamp);

  while (currentFromTimestamp < endTimestamp) {
    const nextToTimestamp = increment(currentFromTimestamp, incrementValue);

    segments.push({
      fromTimestamp: formatISO(currentFromTimestamp),
      toTimestamp: formatISO(nextToTimestamp),
      production: production,
      consumption: consumption
    });

    currentFromTimestamp = nextToTimestamp;
  }

  return segments;
}