import { LanguageKey } from "src/config/translations/translationsTypes";
import { BatteryAvailabilityPlanValidationError } from "src/graphql/graphql";
import { ValidationError } from "./ValidationError";

export function createValidationErrors(
  apiValidationErrors:
    | BatteryAvailabilityPlanValidationError[]
    | null
    | undefined,
): ValidationError[] {
  if (!apiValidationErrors) {
    return [];
  }

  const result = apiValidationErrors.flatMap<ValidationError>((error) => {
    return error.translationKeys.map<ValidationError>((message) => {
      return {
        line: error.line!,
        message: mapValidationErrorToTranslationKey(message),
      };
    });
  });

  return result;
}

function mapValidationErrorToTranslationKey(cause: string): LanguageKey {
  switch (cause) {
    case "EmptyAvailabilityPlan":
    case "ToBeforeFrom":
    case "NegativeValue":
    case "OverlappingDateRange":
    case "TimeGap":
    case "MaxPowerExceeded":
    case "PastDateTime":
      return cause;
    default:
      return "Errors";
  }
}
