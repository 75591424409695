import { useTranslation } from "src/translations/translationProvider";
import styles from "./BatteryAvailability.module.scss";
import { useState } from "react";
import LoadingSpinner from "src/components/loadingSpinner";
import classNames from "classnames";
import PlantSelector from "src/modules/energy/plant/plantSelector";
import { useQuery } from "@apollo/client";
import { GetBatteriesForOrganizationDocument } from "src/graphql/graphql";
import { BatteryAvailabilityVisualizer } from "./BatteryAvailabilityVisualizer/BatteryAvailabilityVisualizer";
import { Tabs } from "@mantine/core";
import { UploadBatteryAvailabilityPlan } from "./UploadBatteryAvailability/UploadBatteryAvailabilityPlan";
import { TABS } from "./tabs";

export function BatteryAvailability({
  organizationId,
}: {
  organizationId: string;
}) {
  const { translate } = useTranslation();
  const [plantId, setPlantId] = useState("");
  const [activeTab, setActiveTab] = useState<string>("batteryAvailability");
  const { loading, error, data, refetch } = useQuery(
    GetBatteriesForOrganizationDocument,
    {
      variables: {
        organizationId,
      },
    },
  );

  if (error || !data) return <div>{translate("UnexpectedError")}</div>;
  const organization = data?.organization ?? { plants: [] };

  if (loading) return <LoadingSpinner />;

  if (organization.plants.length === 0) {
    return (
      <h3 className={classNames(styles.heading, styles.section)}>
        {translate("No plants found")}
      </h3>
    );
  }

  return (
    <section>
      <PlantSelector
        plantId={plantId}
        setPlantId={setPlantId}
        plants={organization.plants}
        loading={loading}
        error={error}
      />
      <Tabs
        defaultValue={TABS.BATTERY_AVAILABILITY}
        onChange={(value) => {
          setActiveTab(value ?? "batteryAvailability");

          if (value == TABS.BATTERY_AVAILABILITY) {
            refetch();
          }
        }}
      >
        <Tabs.List>
          <Tabs.Tab value={TABS.BATTERY_AVAILABILITY} className={styles.tabs}>
            {translate("BatteryAvailability")}
          </Tabs.Tab>
          <Tabs.Tab
            value={TABS.UPLOAD_BATTERY_AVAILABILITY}
            className={styles.tabs}
          >
            {translate("UploadBatteryAvailability")}
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value={TABS.BATTERY_AVAILABILITY} className={styles.tab}>
          <BatteryAvailabilityVisualizer
            organization={organizationId}
            plant={plantId}
          />
        </Tabs.Panel>

        <Tabs.Panel
          value={TABS.UPLOAD_BATTERY_AVAILABILITY}
          className={styles.tab}
        >
          <UploadBatteryAvailabilityPlan
            organizationId={organizationId}
            plantId={plantId}
            isActiveTab={activeTab === "uploadBatteryAvailability"}
          />
        </Tabs.Panel>
      </Tabs>
    </section>
  );
}
