import { useTranslation } from "src/translations/translationProvider";
import Chart, {
  PlaceholderChart,
  PlaceholderChartText,
} from "src/components/chart";


import { DataPlanGraph } from "../dataPlanModel";
import { createChartDataset } from "./createChartDataset";
import { createChartOptions } from "./createChartOptions";

export function IncomingDataPlanChart({
  dataPlanGraphs,
  className,
}: {
  dataPlanGraphs: DataPlanGraph[] | undefined;
  className?: string;
}) {
  const { translate } = useTranslation();

  if (!dataPlanGraphs) {
    return (
      <PlaceholderChart
        className={className}
        key="incoming data plan chart error"
      >
        <PlaceholderChartText>
          {translate("No data found")}
        </PlaceholderChartText>
      </PlaceholderChart>
    );
  }

  return (
    <Chart
      className={className}
      data={createChartDataset({ dataPlans: dataPlanGraphs, translate })}
      options={createChartOptions({ translate })}
    />
  );
}
