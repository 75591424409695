import sjson from "secure-json-parse";

export * from "./mobileRoutes";
export * from "./translations/translations";

export const DEFAULT_LANGUAGE = "se";
export const DEFAULT_LOCALE = "sv-SE";
export const DEFAULT_API_LOCALE = "sv-SE";

export const RESPONSIVE_TABLET = 1024;
export const RESPONSIVE_MOBILE = 768;

export const transitionDuration = 300;
export const MODITY_ORGANIZATION_NAME = "Modity";

export const environments = {
  LOCAL: "LOCAL",
  TEST: "TEST",
  STAGE: "STAGE",
  PRODUCTION: "PRODUCTION",
};

const sharedConfig = {
  defaultHeroImage: "/images/hero-example2.jpg",
  defaultHeroThumbnail: "/images/hero-thumbnail-example.jpg",
  auth: {
    endpointAuth: "authorize",
    endpointLogout: "logout",
    responseType: "id_token+token",
    responseMode: "fragment",
  },
  windyApiKey: "yvUw95ma4l8pf7df49apC3QdHJRU7RQe",
};

const localDevConfig = {
  ...sharedConfig,
  environment: environments.LOCAL,
  graphqlURL: import.meta.env.VITE_GATEWAY_URL
    ? import.meta.env.VITE_GATEWAY_URL + "/graphql"
    : "https://gateway.test.internal.modity.me/graphql",
  fileProxyURL: import.meta.env.VITE_GATEWAY_URL
    ? import.meta.env.VITE_GATEWAY_URL + "/files"
    : "https://gateway.test.internal.modity.me/files",
  appInsightsConnectionString:
    "InstrumentationKey=2ef1d56b-ac30-4d54-a047-3cd0dd40ddde;IngestionEndpoint=https://westeurope-1.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/",
  publicApiURL: "https://api.test.internal.modity.se",
  auth: {
    ...sharedConfig.auth,
    authority:
      "https://modityb2ctest.b2clogin.com/modityb2ctest.onmicrosoft.com/",
    clientId: "f1f236c1-b1b9-4f2e-a5f6-147852bff995",
    knownAuthorities: ["https://modityb2ctest.b2clogin.com"],
    scopes: [
      "https://modityb2ctest.onmicrosoft.com/api/user_impersonation",
      "openid",
      "offline_access",
    ],
    authPolicy: "B2C_1_SignInNext",
  },
};

const testConfig = {
  ...localDevConfig,
  environment: environments.TEST,
  appInsightsConnectionString:
    "InstrumentationKey=2ef1d56b-ac30-4d54-a047-3cd0dd40ddde;IngestionEndpoint=https://westeurope-1.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/",
  graphqlURL: import.meta.env.VITE_GATEWAY_URL
    ? import.meta.env.VITE_GATEWAY_URL + "/graphql"
    : "https://gateway.test.internal.modity.me/graphql",
  fileProxyURL: import.meta.env.VITE_GATEWAY_URL
    ? import.meta.env.VITE_GATEWAY_URL + "/files"
    : "https://gateway.test.internal.modity.me/files",
  publicApiURL: "https://api.test.internal.modity.se",
  auth: {
    ...sharedConfig.auth,
    authority:
      "https://modityb2ctest.b2clogin.com/modityb2ctest.onmicrosoft.com/",
    clientId: "f1f236c1-b1b9-4f2e-a5f6-147852bff995",
    knownAuthorities: ["https://modityb2ctest.b2clogin.com"],
    scopes: [
      "https://modityb2ctest.onmicrosoft.com/api/user_impersonation",
      "openid",
      "offline_access",
    ],
    authPolicy: "B2C_1_SignInNext",
  },
};

const stageConfig = {
  ...sharedConfig,
  environment: environments.STAGE,
  graphqlURL: "https://gateway.stage.modity.me/graphql",
  fileProxyURL: "https://gateway.stage.modity.me/files",
  appInsightsConnectionString:
    "InstrumentationKey=0c1b0777-fb4f-45df-886f-51bc61896a25;IngestionEndpoint=https://westeurope-2.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/",
  publicApiURL: "https://api.stage.modity.live",
  auth: {
    ...sharedConfig.auth,
    authority:
      "https://modityportal.b2clogin.com/modityportal.onmicrosoft.com/",
    clientId: "0689d618-b48d-44a1-b666-0360c93c14a5",
    knownAuthorities: ["https://modityportal.b2clogin.com"],
    scopes: [
      "https://modityportal.onmicrosoft.com/api/user_impersonation",
      "openid",
      "offline_access",
    ],
    authPolicy: "B2C_1_SignInNext",
  },
};

const secondsAday = 86400;
const expireTime = secondsAday * 90;

const productionConfig = {
  ...sharedConfig,
  environment: environments.PRODUCTION,
  graphqlURL: "https://gateway.production.modity.me/graphql",
  fileProxyURL: "https://gateway.production.modity.me/files",
  appInsightsConnectionString:
    "InstrumentationKey=884000f4-bb68-4d61-a059-7bfb849a92e0;IngestionEndpoint=https://westeurope-1.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/",
  googleAnalyticsKey: "G-M8E4NNKMQR",
  gaOptions: {
    cookieExpires: expireTime,
    anonymizeIp: true,
    allowAdFeatures: false,
    allowAdPersonalizationSignals: false,
  },
  publicApiURL: "https://api.modity.live",
  auth: {
    ...sharedConfig.auth,
    authority:
      "https://modityportal.b2clogin.com/modityportal.onmicrosoft.com/",
    clientId: "0689d618-b48d-44a1-b666-0360c93c14a5",
    knownAuthorities: ["https://modityportal.b2clogin.com"],
    scopes: [
      "https://modityportal.onmicrosoft.com/api/user_impersonation",
      "openid",
      "offline_access",
    ],
    authPolicy: "B2C_1_SignInNext",
  },
};

// Copy config object so that changes below don't apply to env specific configs
let config = sjson.parse(JSON.stringify(localDevConfig));

if (
  typeof import.meta.env.B2C_URL !== "undefined" &&
  import.meta.env.B2C_URL.length
) {
  config.auth.url = import.meta.env.B2C_URL;
}
if (
  typeof import.meta.env.B2C_CLIENT_ID !== "undefined" &&
  import.meta.env.B2C_CLIENT_ID.length
) {
  config.auth.clientId = import.meta.env.B2C_CLIENT_ID;
}
if (
  typeof import.meta.env.B2C_AUTH_POLICY !== "undefined" &&
  import.meta.env.B2C_AUTH_POLICY.length
) {
  config.auth.authPolicy = import.meta.env.B2C_AUTH_POLICY;
}
if (
  typeof import.meta.env.B2C_SCOPE !== "undefined" &&
  import.meta.env.B2C_SCOPE.length
) {
  config.auth.scope = import.meta.env.B2C_SCOPE;
}
if (
  typeof import.meta.env.APPINSIGHTS_CONNECTIONSTRING !== "undefined" &&
  import.meta.env.APPINSIGHTS_CONNECTIONSTRING &&
  import.meta.env.APPINSIGHTS_CONNECTIONSTRING.length
) {
  config.appInsightsConnectionString =
    import.meta.env.APPINSIGHTS_CONNECTIONSTRING;
}

const reviewConfig = (pullRequestId: string | undefined) => ({
  ...testConfig,
  graphqlURL: `https://gateway-pr-${pullRequestId}.test.internal.modity.live/graphql`,
  fileProxyURL: `https://gateway-pr-${pullRequestId}.test.internal.modity.live/files`,
  publicApiURL: `https://api-pr-${pullRequestId}.test.internal.modity.live`,
});

// This solution is a bit brittle, but the alternative for reusing artifacts between environments requires splitting code so that a different config file is loaded per environment
// Can be done with code splitting and k8s configmaps, i'd suggest doing this after switch to webpack
const testHost = "test.internal.modity.me";
const stageHost = "stage.modity.me";
const productionHost = "production.modity.me";
const webProductionHost = "modity.me";

const stageLiveHost = "stage.modity.live";
const productionLiveHost = "production.modity.live";
const webProductionLiveHost = "modity.live";

const reviewHostPattern =
  /live-portal-pr-(?<pullRequestId>\d{1,4}).test.internal.modity.live/;

switch (true) {
  case window.location.host === testHost:
    config = testConfig;
    break;
  case window.location.host === stageHost:
    config = stageConfig;
    break;
  case window.location.host === productionHost:
    config = productionConfig;
    break;
  case window.location.host === webProductionHost:
    config = productionConfig;
    break;
  case window.location.host === stageLiveHost:
    config = productionConfig;
    break;
  case window.location.host === productionLiveHost:
    config = productionConfig;
    break;
  case window.location.host === webProductionLiveHost:
    config = productionConfig;
    break;
  case reviewHostPattern.test(window.location.host):
    const match = reviewHostPattern.exec(window.location.host);
    config = reviewConfig(match?.groups?.pullRequestId);
    break;
  default:
    config = testConfig;
}

export default config;
