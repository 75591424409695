import { useQuery } from "@apollo/client";
import { useState } from "react";
import { GetIncomingDataPlanDocument } from "src/graphql/graphql";
import { IncomingDataPlanChart } from "../DataPlanChart/DataPlanChart";
import globalStyles from "src/styles/global.module.scss";
import styles from "./IncomingDataPlan.module.scss";
import { getWeekDateRangeISO, mapToDataPlanGraph } from "../utils/incomingDataPlanUtils";
import { IncomingDataPlanTable } from "./IncomingDataPlanTable";
import { formatISO } from "date-fns";
import { DatePickerInput } from "@mantine/dates";
import "dayjs/locale/en";
import "dayjs/locale/sv";
import { useTranslation } from "src/translations/translationProvider";
import LoadingSpinner from "src/components/loadingSpinner";

export const IncomingDataPlan = ({ 
  organizationId, 
  selectedGroupId, 
} : { 
  organizationId: string;
  selectedGroupId: number; 
}) => {
  const initialWeekRange = getWeekDateRangeISO(formatISO(new Date().setHours(0, 0, 0, 0)));
  const [dateRange, setDateRange] = useState<[string | null, string | null]>([
  initialWeekRange.From, 
  initialWeekRange.To
]);
  const { locale, translate } = useTranslation();
  const localeOnly = locale.split("-")[0];
  
  const { loading, data } = useQuery(GetIncomingDataPlanDocument, {
    variables: {
      organizationId,
      groupId: selectedGroupId!,
      from: dateRange[0]!,
      to: dateRange[1]!,
    },
    skip: selectedGroupId === 0 || !dateRange[1],
    onError: (error) => {
      console.log("error", error);
    }
  });

  return (
    <section className={globalStyles.sectionTitleLarge}>
      <DatePickerInput
        type="range"
        label={translate("IncomingPeriod")}
        placeholder={translate("IncomingPeriod")}
        value={dateRange.map((date) => (date ? new Date(date) : null)) as [Date | null, Date | null]}
        onChange={(range) => {
          setDateRange([
            range[0] ? formatISO(range[0]) : null,
            range[1] ? formatISO(range[1]) : null,
          ])
        }
        }
        locale={localeOnly}
        className={styles.datepicker}
      />
      {loading ? (
      <LoadingSpinner />
    ) : (
      <>
        <IncomingDataPlanChart dataPlanGraphs={mapToDataPlanGraph(data?.incomingDataPlan ?? [])} className={styles.chart} />
        <IncomingDataPlanTable dataPlans={mapToDataPlanGraph(data?.incomingDataPlan ?? [])} />
      </>
    )}
    </section>
  );
}



