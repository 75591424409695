import React, { useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import classNames from "classnames";

import { useClick, useEscape, useEnter } from "/src/utilities/hooks";

import styles from "./dialog.module.scss";

const Dialog = ({
  className,
  children,
  title,
  dialogSupport,
  forceClose,
  onConfirm,
  dialogSupportclassName,
}) => {
  const ref = useRef();

  useClick((event) => {
    // Prevents the dialog component from triggering forceClose when clicking inside the Mantine calendar
    const stopPropagation = event.target.getAttribute("data-mantine-stop-propagation");  
  
    if (stopPropagation === "true") {
      event.stopPropagation()

      //If dialog has already been closed (e.g. using close button), ref.current is null
    } else if (ref.current && !ref.current.contains(event.target)) {
      forceClose();
    }
  });

  useEscape(() => {
    forceClose();
  }, []);

  useEnter(() => {
    if (onConfirm && ref.current.contains(window.event.target)) {
      onConfirm();
    }
  }, [children, onConfirm]);

  useEffect(() => {
    ref.current && ref.current?.focus();
  }, []);

  return ReactDOM.createPortal(
    <div className={styles.scrim}>
      <div
        ref={ref}
        data-test-id="dialog"
        tabIndex="0"
        className={classNames(styles.dialog, className)}
      >
        <div className={styles.dialogTitle}>{title}</div>
        {dialogSupport && (
          <div
            className={classNames(styles.dialogSupport, dialogSupportclassName)}
          >
            {dialogSupport}
          </div>
        )}
        <div className={styles.buttonWrapper}>{children}</div>
      </div>
    </div>,
    document.body
  );
};

Dialog.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  cancellationText: PropTypes.string,
  dialogSupport: PropTypes.node,
  onCancellationClick: PropTypes.func,
  children: PropTypes.node,
  forceClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default Dialog;
