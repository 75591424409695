import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "src/translations/translationProvider";
import config from "src/config/config";
import AuthLink from "src/components/authLink";
import Button from "src/components/button";
import Dialog from "src/components/dialog";
import { templateType } from "src/constants/templateTypes";
import { DatePickerInput } from "@mantine/dates";
import NumInput from "src/components/input/numberInput";

import styles from "./downloadTemplate.module.scss";

const MINIMUM_DAYS = 1;
const DEFAULT_DAYS = 1;
const MAXIMUM_DAYS = 365;

const DownloadTemplate = ({
  organizationId,
  className,
  plantId,
  plantType,
}) => {
  const { translate, language, locale } = useTranslation();
  const [amountOfDays, setAmountOfDays] = useState(DEFAULT_DAYS);
  const [openDialog, setOpenDialog] = useState(false);
  const [startDate, setStartDate] = useState((new Date()))
  
  const downloadUrl =
  plantType == templateType.WIND
  ? `${config.fileProxyURL}/availabilityplans/${organizationId}/template/${plantId}?days=${amountOfDays}&language=${language}`
  : `${config.fileProxyURL}/availabilityplanbatteries/${organizationId}/template/${plantId}?days=${amountOfDays}&startDate=${startDate.toISOString()}&language=${language}`;

  
  const dayInputValid = (days) => days <= MAXIMUM_DAYS && days >= MINIMUM_DAYS;
  const localeOnly = locale.split("-")[0];

  return (
    <div className={className}>
      <Button
        small
        onClick={(e) => {
          e.stopPropagation();
          setOpenDialog(true);
        }}
      >
        {translate("Download Template")}
      </Button>
      {openDialog && (
        <Dialog
          title={translate("downloadTemplateDialogTitle")}
          forceClose={() => setOpenDialog(false)}
          dialogSupport={
            <section className={styles.inputFields}>
              <NumInput 
                label={translate("Amount of days")}
                value={amountOfDays}
                onChange={setAmountOfDays}
                min={MINIMUM_DAYS}
                max={MAXIMUM_DAYS}
              />
            <DatePickerInput
              classNames={{
                label: styles.inputLabel,
                input: styles.inputInput
              }}
              valueFormat="DD MMMM YYYY"
              label={translate("StartDate")}
              placeholder={translate("StartDate")}
              defaultValue={new Date()}
              minDate={new Date()}
              value={startDate}
              onChange={setStartDate}
              locale={localeOnly}
            />                
            </section>
          }
        >
          <button onClick={() => setOpenDialog(false)}>
            {translate("Cancel")}
          </button>
          <AuthLink className={styles.downloadButton} url={downloadUrl}>
            <Button
              small
              disabled={!dayInputValid(amountOfDays)}
              onClick={() => setOpenDialog(false)}
            >
              {translate("Download Template")}
            </Button>
          </AuthLink>
        </Dialog>
      )}
    </div>
  );
};

DownloadTemplate.propTypes = {
  className: PropTypes.string,
  organizationId: PropTypes.string.isRequired,
  plantId: PropTypes.string,
  plantType: PropTypes.string,
};

export default DownloadTemplate;
