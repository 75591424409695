import {
  isNumber,
  isValidDate,
  ParsedExcel,
  ValidateParsedExcel,
} from "src/utilities/parseExcel";
import { MissingTimeRange, MissingTimeRangeResult, ParsedExcelError, ProductionConsumptionExcelData } from "../uploadProductionConsumptionPlanModel";
import { parseISO, formatISO } from "date-fns";
import { PeriodSize, ProductionConsumptionPlanInput } from "src/graphql/graphql";
import { getPeriodSizeConfig } from "../../utils/incomingDataPlanUtils";

export function validateParsedIncomingDataPlanExcel(
  parsedExcel: ParsedExcel<ProductionConsumptionExcelData>,
): {
  errors: ParsedExcelError[];
  values: ProductionConsumptionPlanInput[];
} {
  return ValidateParsedExcel(parsedExcel, validateRowAndCreateErrors);
}

function validateRowAndCreateErrors(
  row: ParsedExcel<ProductionConsumptionExcelData>["data"][0],
): ProductionConsumptionPlanInput | ParsedExcelError[] {
  const errors: ParsedExcelError[] = [];
  if (!isValidDate(row["A"])) {
    errors.push({
      column: "A",
      row: row.__RowNum__,
      value: row["A"],
      message: "invalid date format",
    });
  }
  if (!isValidDate(row["B"])) {
    errors.push({
      column: "B",
      row: row.__RowNum__,
      value: row["B"],
      message: "invalid date format",
    });
  }
  if (row.C != null && !isNumber(row.C)) {
    errors.push({
      column: "C",
      row: row.__RowNum__,
      value: row.C.toString(),
      message: "invalid number value",
    });
  }
  if (row["D"] != null && !isNumber(row["D"])) {
    errors.push({
      column: "D",
      row: row.__RowNum__,
      value: row["D"].toString(),
      message: "invalid number value",
    });
  }

  if (errors.length === 0) {
    return {
      fromTimestamp: row.A as ProductionConsumptionPlanInput["fromTimestamp"],
      toTimestamp: row.B as ProductionConsumptionPlanInput["toTimestamp"],
      production: row.C === null ? 0 : Number(row.C),
      consumption: row.D === null ? 0 : Number(row.D),
    };
  }

  return errors;
}

export function validateAndGetMissingTimeRange(
  inputs: ProductionConsumptionPlanInput[],
  periodSize: PeriodSize
): MissingTimeRangeResult {
  if (inputs.length <= 1) return {matchedPeriodSize: true, missingHours: []};

  const sortedInputs = [...inputs].sort((a, b) =>
    parseISO(a.fromTimestamp).getTime() - parseISO(b.fromTimestamp).getTime()
  );

  const missingTimeRanges: MissingTimeRange[] = [];
  const { increment, incrementValue, difference } = getPeriodSizeConfig(periodSize);

  for (let i = 0; i < sortedInputs.length; i++) {
    const current = sortedInputs[i]!;
    const next = sortedInputs[i + 1];
    if (!next) continue;

    let lastToTimestamp = parseISO(current.toTimestamp);
    const nextFromTimestamp = parseISO(next.fromTimestamp);

    const currentRangeDifference = difference(lastToTimestamp, parseISO(current.fromTimestamp));
    if (currentRangeDifference !== incrementValue) {
      return {matchedPeriodSize: false, missingHours: []};
    }

    let timeDifference = difference(nextFromTimestamp, lastToTimestamp);
    while (timeDifference >= incrementValue) {
      const newFromTimestamp = lastToTimestamp;
      const newToTimestamp = increment(newFromTimestamp, incrementValue);
      missingTimeRanges.push({
        fromTimestamp: formatISO(newFromTimestamp),
        toTimestamp: formatISO(newToTimestamp),
      });
      lastToTimestamp = newToTimestamp;
      timeDifference = difference(nextFromTimestamp, lastToTimestamp);
    }
  }

  return {matchedPeriodSize: true, missingHours: missingTimeRanges};
}

export function validatePeriodSizeForPlanInput(
  dataPlanArray: ProductionConsumptionPlanInput[],
  periodSize: PeriodSize
): boolean {
  if (dataPlanArray.length < 2) {
    return true;
  }

  const periodInMillis: Record<PeriodSize, number> = {
    [PeriodSize.M15]: 15 * 60 * 1000,
    [PeriodSize.H1]: 60 * 60 * 1000,
  };

  const expectedDifference = periodInMillis[periodSize];

  for (let i = 0; i < dataPlanArray.length - 1; i++) {
    const current = dataPlanArray[i];
    const next = dataPlanArray[i + 1];

    if (!current || !next) {
      return false;
    }

    const currentTo = new Date(current.toTimestamp).getTime();
    const nextFrom = new Date(next.fromTimestamp).getTime();

    if (nextFrom - currentTo !== expectedDifference) {
      return false;
    }
  }

  return true;
}
