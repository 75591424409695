import React, { Suspense, lazy } from "react";
import PropTypes from "prop-types";
import { Route, Routes } from "react-router-dom";
import ImpersonatedWarning from "src/modules/impersonatedWarning";
import EnvironmentWarning from "src/components/environmentWarning";
import { NavBar, NavBarMobile } from "src/components/navBar";
import { Mobile, NotMobile } from "src/components/responsive";
import { ReadAccessControl } from "src/components/accessControl";
import { ErrorBoundary } from "src/components/error";
import NotIframe from "src/components/notIframe";
import Logotype from "src/components/logotype";
import ContentWrapper from "./contentWrapper";

import styles from "./app.module.scss";

import Dashboard from "src/modules/dashboard";
import ErrorPage from "src/modules/error-page";
import Knowledge from "src/modules/knowledge";
import Energy from "src/modules/energy";
import Portfolio from "src/modules/portfolio";
import Article from "src/modules/article";
import Files from "src/modules/files";
import ContactPanel from "src/modules/contactPanel";
import UserAgreement from "src/modules/userAgreement";
import CookieConsent from "src/components/cookieConsent";
import EditProfile from "./editProfile";
import { CustomerData } from "src/modules/app/customerdata/CustomerData";

const NetMW = lazy(() => import("src/modules/netMW"));
const ClientAdmin = lazy(() => import("src/modules/admin/client"));
const ModityAdmin = lazy(() => import("src/modules/admin/modity"));
const Developer = lazy(() => import("src/modules/developer"));

const restrict = (types, element) => (
  <ReadAccessControl
    fallback={<ErrorPage />}
    operator={"or"}
    restrictionTypes={types}
  >
    {element}
  </ReadAccessControl>
);

const ApplicationRoutes = () => (
  <>
    <Suspense fallback={<></>}>
      <EditProfile />
      <ContactPanel />
      <CookieConsent />
      <div className={styles.app}>
        <Mobile>
          <NotIframe>
            <NavBarMobile />
          </NotIframe>
        </Mobile>
        <NotMobile>
          <NotIframe>
            <NavBar />
          </NotIframe>
        </NotMobile>
        <ContentWrapper>
          <ImpersonatedWarning />
          <EnvironmentWarning />
          <NotMobile>
            <NotIframe>
              <Logotype />
            </NotIframe>
          </NotMobile>
          <ErrorBoundary path={window.location.pathname}>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="files" element={<Files />}>
                <Route path=":tab" element={<Files />} />
              </Route>
              <Route
                key="knowledge"
                path="/knowledge"
                element={restrict(["Knowledge"], <Knowledge />)}
              />
              <Route
                path="article/*"
                element={restrict(["Knowledge"], <Article />)}
              />
              <Route
                path="admin/*"
                element={restrict(["Administration"], <ClientAdmin />)}
              >
                <Route
                  path={":tab/*"}
                  element={restrict(["Administration"], <ClientAdmin />)}
                />
              </Route>
              <Route
                path="modity_admin/*"
                element={restrict(["ModityAdmin"], <ModityAdmin />)}
              >
                <Route
                  path={":tab/*"}
                  element={restrict(["ModityAdmin"], <ModityAdmin />)}
                />
              </Route>
              <Route
                path="/developer/*"
                element={restrict(["Developer"], <Developer />)}
              >
                <Route
                  path=":tab"
                  element={restrict(["Developer"], <Developer />)}
                />
              </Route>
              <Route
                path="/energy/*"
                element={restrict(["Energy"], <Energy />)}
              >
                <Route path=":tab" element={restrict(["Energy"], <Energy />)} />
              </Route>
              <Route
                path="customerdata"
                element={restrict(
                  ["IncomingData", "Flexibility"],
                  <CustomerData />,
                )}
              >
                <Route
                  path=":tab"
                  element={restrict(
                    ["IncomingData", "Flexibility"],
                    <CustomerData />,
                  )}
                >
                </Route>
              </Route>
              <Route path="/netmw" element={restrict(["NetMW"], <NetMW />)} />
              <Route
                path="portfolio"
                element={restrict(
                  ["PortfolioPhysical", "PortfolioFinancial"],
                  <Portfolio />,
                )}
              >
                <Route
                  path=":tab"
                  element={restrict(
                    ["PortfolioPhysical", "PortfolioFinancial"],
                    <Portfolio />,
                  )}
                >
                  <Route
                    path=":subpage"
                    element={restrict(
                      ["PortfolioPhysical", "PortfolioFinancial"],
                      <Portfolio />,
                    )}
                  >
                    <Route
                      path=":subpageLvl2"
                      element={restrict(
                        ["PortfolioPhysical", "PortfolioFinancial"],
                        <Portfolio />,
                      )}
                    />
                  </Route>
                </Route>
              </Route>
              <Route path={"/agreement"} element={<UserAgreement />} />
              <Route path={"*"} element={<ErrorPage />} />
            </Routes>
          </ErrorBoundary>
        </ContentWrapper>
      </div>
    </Suspense>
  </>
);

ApplicationRoutes.propTypes = {
  mutate: PropTypes.func,
  location: PropTypes.object,
  history: PropTypes.object,
};

export default ApplicationRoutes;
