import styles from "./BatteryAvailabilityTable.module.scss";
import Table from "src/components/table";
import { BatteryAvailabilityPlanValue } from "src/graphql/graphql";
import { useTranslation } from "src/translations/translationProvider";
import { addHours } from "date-fns";

interface FormattedDataPlan {
    fromTimestamp: string;
    toTimestamp: string;
    power: number;
}

export const BatteryAvailabilityTable = ({ data = [] }: { data?: BatteryAvailabilityPlanValue[] }) => {

  const { translate, formatDate } = useTranslation();
  
  const formatDataForTable = (data: BatteryAvailabilityPlanValue[]) : FormattedDataPlan[] => {
    return data.map((plan) => {
      return {
        fromTimestamp: new Date(plan.timestamp).toISOString(),
        toTimestamp: addHours(new Date(plan.timestamp), 1).toISOString(),
        power: plan.power,
      }
    })
  }

  const renderHeader = (label: string, unit: string) => (
    <section className={styles.columnHeader}>
      <h4 className={styles.columnHeaderLabel}>{label}</h4>
      <span className={styles.columnHeaderUnit}>{unit}</span>
    </section>
  );

  const columns = [
    {
      Header: () => renderHeader(translate("From"), "UTC"),
      accessor: "fromTimestamp",
      Cell: ({ value } : {value: string}) => formatDate(new Date(value), "yyyy-MM-dd HH:mm"),
      name: translate("From"),
      show: true,
    },
    {
      Header: () => renderHeader(translate("To"), "UTC"),
      accessor: "toTimestamp",
      Cell: ({ value } : {value: string}) => formatDate(new Date(value), "yyyy-MM-dd HH:mm"),
      name: translate("To"),
      show: true,
    },
    {
      Header: () => renderHeader(translate("PrequalifiedEffect"), "MW"),
      accessor: "power",
      Cell: ({ value } : {value: number}) => value,
      name: translate("PrequalifiedEffect"),
      show: true,
    },
  ];

  return (
    <Table
      headerClassName={styles.cell}
      cellClassName={styles.cell}
      data={formatDataForTable(data)}
      columns={columns}
      sortable
      exportable
      tableOptions={{
      initialState: {
        sortBy: [{
        id: "fromTimestamp",
        desc: false,
        }],
      },
      disableSortRemove: true,
      }}
      highlightChangedRow={{ propToCheck: "fromTimestamp" }}
      clickable={{}} 
      className={""} 
      useFooter={false} 
      selectable={false} 
      selectedButtonText="Select Rows" 
      handleSelected={() => {}} 
      truncateDate={false}
    />
  )
}


