import { useState } from "react";
import { Select, Tabs } from "@mantine/core"
import { useTranslation } from "src/translations/translationProvider";
import { IncomingDataPlan } from "./IncomingDataPlan/IncomingDataPlan";
import { UploadProductionConsumptionPlan } from "./UploadProductionConsumptionPlans/UploadProductionConsumptionPlan";
import { useQuery } from "@apollo/client";
import { GetDataGroupsDocument, PeriodSize } from "src/graphql/graphql";
import LoadingSpinner from "src/components/loadingSpinner";
import classNames from "classnames";
import styles from "./ProductionConsumption.module.scss";

export const ProductionConsumption = ({ organizationId } : { organizationId: string }) => {
  const [selectedPeriodSize, setSelectedPeriodSize] = useState(PeriodSize.M15)
  const [activeTab, setActiveTab] = useState<string>("incomingData");
  const { translate } = useTranslation();
  const [selectedGroupId, setSelectedGroupId] = useState<number>();

  const { loading, error, data } = useQuery(GetDataGroupsDocument, {
    variables: {
      organizationId: organizationId,
    },
    onCompleted: (data) => {
      if(data.organizationDataGroups.dataGroups.length > 0)
      {
        setSelectedGroupId(data.organizationDataGroups.dataGroups[0]?.id ?? 0)
      }
    }
  });

  const dataGroups = data?.organizationDataGroups?.dataGroups ?? [];

  const selectableDataGroups = (dataGroups ?? []).map((group) => ({
    value: group.id.toString(),
    label: group.name,
  }));

  const handleDataGroupSelectionChange = (value: string | null) => {
    if (value === null) return;
  
    const selectedId = Number(value);
    if (isNaN(selectedId)) { return; }

    setSelectedGroupId(selectedId);

    const selectedGroup = dataGroups?.find(group => group.id === selectedId);
    setSelectedPeriodSize(selectedGroup?.periodSize ?? PeriodSize.M15);
  };

  if (selectableDataGroups.length === 0) {
    return (
      <h3 className={classNames(styles.heading, styles.section)}>
        {translate("No datagroups found")}
      </h3>
    );
  }

  if (loading) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  if (error || !data) {
    console.log(error); // eslint-disable-line no-console
    return <div>{translate("UnexpectedError")}</div>;
  }
  
  return(
    <div>
      <Select
        label={translate("Select Data Group")}
        data={selectableDataGroups}
        value={selectedGroupId?.toString() || ""}
        onChange={handleDataGroupSelectionChange}
        clearable={false}
        mb={"lg"}
      />
      <Tabs defaultValue="incomingData" onChange={(value) => setActiveTab(value ?? "incomingData")}>
        <Tabs.List>
          <Tabs.Tab value="incomingData" className={styles.tabs}>
            {translate("IncomingDataPlan")}
          </Tabs.Tab>
          <Tabs.Tab value="uploadIncomingData" className={styles.tabs}>
              {translate("UploadIncomingDataPlan")}
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="incomingData" className={styles.tab}>
          <IncomingDataPlan organizationId={organizationId}
            selectedGroupId={selectedGroupId!} 
          />
        </Tabs.Panel>

        <Tabs.Panel value="uploadIncomingData" className={styles.tab}>
          <UploadProductionConsumptionPlan 
            organizationId={organizationId}
            periodSize={selectedPeriodSize}
            selectedGroupId={selectedGroupId!}
            isActiveTab={activeTab === "uploadIncomingData"}
          />
        </Tabs.Panel>
      </Tabs>
    </div>
  )
}